import * as React from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { colors } from "../../styles/colors";
import ListButton from "../generic/buttons/ListButton";
import BoxModal from "../generic/modal/BoxModal";
import RGTokenIcon from "../RGTokenIcon";
import { removeMacros } from "../../store/slices/macrosSlice";
import { useNavigation } from "@react-navigation/native";

function MacroItem({ macroGroup, macro, onSelected, options, rolling, dispatch, user, game }) {
	const deleteModalRef = React.useRef();
	const navigation = useNavigation();
	options = options || [];

	const allowEdit = user && macroGroup.user === user.id;

	if (allowEdit) {
		if (rolling) {
			!global.appleStoreReview && options.push({
				title: _("Edit"),
				danger: true,
				onPress: () => navigation.navigate("MacroScreen", { macro, sheetId: game.sheet_template }),
				requiredLevel: 2,
				navigation: useNavigation()
			});
		} else {
			options.push({
				title: _("Delete"),
				danger: true,
				onPress: () => deleteModalRef.current.show(),
			});
		}
	}
	const deleteMacro = React.useCallback(() => {
		dispatch(removeMacros([macro]));
		apiFetch(`users/${user.id}/dice-macros/${macro.id}`, "DELETE");
	}, [macro, dispatch, user.id]);

	return (
		<>
			<ListButton
				options={options}
				title={macro.name}
				subtitle={macro.description || macro.command}
				onPress={() => onSelected && onSelected(macro)}
				subtitleTextProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
				iconLeft={{ type: RGTokenIcon, name: macro.icon, color: global.colors.userColors[macro.color] }}
			/>
			<BoxModal
				ref={deleteModalRef}
				message={_("Are you sure you want to delete the macro %(macro)s? This action cannot be reverted.", "", {
					macro: macro.name,
				})}
				isDelete
				onConfirm={deleteMacro}
			/>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user,
		game: state.games[state.games.currentId],
	};
};

export default connect(mapStateToProps)(MacroItem);


