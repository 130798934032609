import { useNavigation, useRoute } from "@react-navigation/native";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { getCurrentCharacter } from "../../store/slices/charactersSlice";
import { getCurrentGameUI } from "../../store/slices/gamesUISlice";
import { getCharName } from "../../tools/characters";
import { isGM } from "../../tools/games";
import { SELECT_CHARACTER_CHANGE_ACTIVE } from "../characters/FilteredCharacterList";
import AppButton from "../generic/buttons/AppButton";
import PopoverMessage from "../generic/popover/PopoverMessage";
import ChatInput from "./ChatInput";

import { BuildStyleMethod } from "../../styles/theming";
import { MaterialIcons } from "@expo/vector-icons";
import CurrentCharacterButton from "./CurrentCharacterButton";
import SearchBar from "../generic/Searchbar";
import { debounceValue } from "../../tools/react";

function ChatOptions({
	dispatch,
	user,
	currentParty,
	currentCharacter,
	characters,
	whisperToCharacterIds,
	whisperToPlayerIds,
	talkToCharacterIds,
	talkToPlayerIds,
	onSearchUpdatedCallback,
	failedSearch
}) {
	const styles = stylesMethod(global.theme);
	const [mode, setmode] = React.useState(null);
	const [selectingCharacterToSay, setselectingCharacterToSay] = React.useState(false);
	const route = useRoute();
	const navigation = useNavigation();

	const [ showSearchBar, setShowSearchBar ] = React.useState(false);

	const [searchValue, setSearchValue] = React.useState("");
	const [debouncedSearchValue, setDebouncedSearchValue] = React.useState("");

	const debounceSearch = debounceValue(searchValue, 700);

	React.useEffect(() => {
		setDebouncedSearchValue(searchValue)
	}, [debounceSearch]);

	React.useEffect(() => {
		onSearchUpdatedCallback(debounceSearch);
	}, [debouncedSearchValue]);

	const showSearchBarCallback = React.useCallback(() => {
		setShowSearchBar((current_value) => !current_value);
		setmode(null);
	})

	const toggleDescribe = React.useCallback(() => {
		setmode((currValue) => (currValue === "description" ? null : "description"));
		setShowSearchBar(false);
	}, []);

	const toggleSay = React.useCallback(() => {
		setmode((currValue) => (currValue === "dialogue" ? null : "dialogue"));
		setShowSearchBar(false);
	}, []);

	const toggleChat = React.useCallback(() => {
		setmode((currValue) => (currValue === "chat" ? null : "chat"));
		setShowSearchBar(false);
	}, []);

	const openCharacterSelection = React.useCallback(() => {
		setShowSearchBar(false);
		let charactersAvailable = null;

		if (!isGM()) {
			charactersAvailable = characters.filter((c) => !c.deleted && c.player?.id === user.id);
		}
		setselectingCharacterToSay(true);
		navigation.navigate("QuickCharacterSearchScreen", {
			sendToScreen: route.name,
			charactersAvailable,
			showNarrator: false,
			code: SELECT_CHARACTER_CHANGE_ACTIVE,
		});
	}, [characters, user.id]);

	React.useEffect(() => {
		if (
			!mode &&
			(whisperToCharacterIds?.length ||
				whisperToPlayerIds?.length ||
				talkToCharacterIds?.length ||
				talkToPlayerIds?.length)
		) {
			if(talkToPlayerIds?.length){
				setmode("chat");
			}
			else if (currentCharacter) {
				setmode("dialogue");
			} else {
				setmode("description");
			}
		}
	}, [mode, whisperToCharacterIds, whisperToPlayerIds, talkToCharacterIds, talkToPlayerIds, currentCharacter]);

	React.useEffect(() => {
		if (!currentCharacter && mode === "dialogue" && !selectingCharacterToSay) {
			setmode(null);
		}
	}, [currentCharacter, mode, selectingCharacterToSay]);

	React.useEffect(() => {
		setselectingCharacterToSay(false);
	}, [currentCharacter]);

	React.useEffect(() => {
		const code = route.params?.characterSelectedCodeSent;
		if (code !== SELECT_CHARACTER_CHANGE_ACTIVE) return () => null;
		const characterSelected = characters.rg_findById(route.params?.characterSelectedId);
		if (characterSelected && selectingCharacterToSay) {
			setmode("dialogue");
		}
	}, [route.params]);

	const searchStyles = {
		...styles.searchBar,
		borderStyle: !!failedSearch ? 'solid' : 'none'
	};

	return (
		<View style={styles.container}>
			<ChatInput show={!!mode} messageType={mode} />
			{showSearchBar && 
				<SearchBar 
					autofocus
					style={searchStyles}
					placeholder={_("Search game text...")}
					onSearchUpdated={setSearchValue}
				/>
			}
			<View style={styles.buttonContainer}>
				<CurrentCharacterButton style={{justifyContent: "center", marginRight: 5}} show messageType={mode} size={32} /><AppButton
					transparentHighlight
					title={_("Describe", "option to act as your character")}
					style={styles.button}
					hoverStyle={styles.buttonHover}
					popoverProps={{
						popoverComponent: (
							<PopoverMessage
								title={_("Describe", "option to act as your character")}
								message={
									currentCharacter
										? _("Describe an action %(character_name)s does", "describe button info", {
												character_name: getCharName(currentCharacter),
										  })
										: _("Describe the current scene as the Narrator", "describe button info")
								}
							/>
						),
						arrowPosition: "bottom",
					}}
					onPress={toggleDescribe}
				/>
				<AppButton
					transparentHighlight
					title={_("Say", "option to talk as your character")}
					style={styles.button}
					hoverStyle={styles.buttonHover}
					popoverProps={{
						popoverComponent: (
							<PopoverMessage
								title={_("Say", "option to talk as your character")}
								message={
									currentCharacter
										? _("Talk as %(character_name)s", "say button info", {
												character_name: getCharName(currentCharacter),
										  })
										: _(
												"Select a character to talk as",
												"say button info (when no character is selected)"
										  )
								}
							/>
						),
						arrowPosition: "bottom",
					}}
					onPress={currentCharacter ? toggleSay : openCharacterSelection}
				/>
				<AppButton
					transparentHighlight
					title={_("Chat", "option to chat out of character")}
					style={styles.button}
					hoverStyle={styles.buttonHover}
					popoverProps={{
						popoverComponent: (
							<PopoverMessage
								title={_("Chat", "option to chat out of character")}
								message={_("Talk out of character, as %(username)s", "chat button info", {
									username: user.username,
								})}
							/>
						),
						arrowPosition: "bottom",
					}}
					onPress={toggleChat}
				/>
				<AppButton
					transparentHighlight
					style={styles.button}
					icon={{ type: MaterialIcons, name: "image", color: global.colors.textLight }}
					onPress={() => navigation.navigate("UploadImageModal")}
					requiredLevel={2}
					allowLegendaryShare
					popoverProps={{
						popoverComponent: (
							<PopoverMessage
								title={_("Upload an image")}
								message={
									currentCharacter
										? _("Send an image as %(character_name)s", "upload image button info", {
												character_name: getCharName(currentCharacter),
										  })
										: _(
												"Send an image as the Narrator",
												"upload image button info (when no character is selected)"
										  )
								}
							/>
						),
						arrowPosition: "bottom",
					}}
				/>
				<AppButton
					transparentHighlight
					style={styles.button}
					hoverStyle={styles.buttonHover}
					onPress={showSearchBarCallback}
					icon={{ type: MaterialIcons, name: "search", color: global.colors.textDefault }}
				/>
			</View>
		</View>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = state.games.currentId;
	const currentCharacter = getCurrentCharacter(state);
	const gameUI = getCurrentGameUI(state);

	return {
		user: state.user,
		currentParty: state.parties[gameId].current,
		currentCharacter,
		sheets: state.sheets[currentCharacter?.sheet], // used to refresh character names
		characters: state.charactersByGame[gameId],
		whisperToCharacterIds: gameUI.whisperToCharacterIds,
		whisperToPlayerIds: gameUI.whisperToPlayerIds,
		talkToCharacterIds: gameUI.talkToCharacterIds,
		talkToPlayerIds: gameUI.talkToPlayerIds,
	};
};

export default connect(mapStateToProps)(ChatOptions);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			flex: 1,
			alignItems: "center",
		},
		buttonContainer: {
			flexDirection: "row",
			justifyContent: "space-evenly",
			width: 550,
			marginLeft: -30,
		},
		button: {
			minHeight: 38,
			height: 38,
			width: 92,
		},
		buttonHover: {
			backgroundColor: colors.hoverItemLightBackground,
		},
		searchBar: {
			width: 450,
			borderWidth: 2,
			borderRadius: 16,
			borderColor: colors.attentionImportant
		}
	})
);
