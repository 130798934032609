import { useLinkProps, useNavigation } from "@react-navigation/native";
import * as React from "react";
import { Linking, StyleSheet, TouchableOpacity, View } from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { BuildStyleMethod } from "../../../styles/theming";
import { HasLevelOrShare } from "../../../tools/account";
import { isWeb, ws } from "../../../tools/generic";
import CondView from "../../meta/CondView";
import LockedFeatureModal from "../../profile/LockedFeatureModal";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import Popover from "../popover/Popover";
import UnreadBadge from "../UnreadBadge";

let showPopoverTimeout = null;

function AppButton({
	title,
	onPress,
	size = "average",
	centerButton = true,
	navigateTo,
	disabled,
	list,
	info,
	icon,
	subicon,
	danger,
	isToggle,
	toggled,
	footerBack,
	footerForward,
	transparent,
	transparentHighlight,
	hide,
	iconComponent,
	floating,
	style,
	styleText,
	styleTextContainer,
	hoverStyle,
	infoTextProps,
	requiredLevel,
	allowLegendaryShare,
	badgeValue,
	popupMode,
	iconContainerStyle,
	callToAction,
	popoverProps,
	popoverDelay = 500,
	overrideHoverStyle,
	to,
	action,
	navigation,
	dispatch,
	currentLevel,
}) {
	const styles = stylesMethod(global.theme);
	const lockedModalRef = React.useRef();
	const popoverRef = React.useRef();

	// Save the value of to, because if it is changed, a "rendered more hooks than in the previous render" crash will occur
	// due to the usage of useLinkProps
	const [toProps, settoProps] = React.useState(to);

	let { onPress: navOnPress, ...linkProps } = toProps ? useLinkProps({ to:toProps, action }) : {onPress: null};

	// Only use navigation if really needed, in case the button is outside the navigation provider
	// as it is the case with EditTextModal
	if (!navigation && (requiredLevel || navigateTo)) navigation = useNavigation();
	if (navigateTo) {
		onPress = React.useCallback(() => {
			navigation.navigate(navigateTo);
		}, [navigateTo]);
	}

	let Container = TouchableOpacity;

	const useExternalURL = toProps && toProps.startsWith("http");
	if (useExternalURL) {
		navOnPress = null;
		onPress = null;
	}
	if (toProps) {
		if (isWeb()) {
			Container = View;
		} else {
			onPress = useExternalURL ? () => Linking.openURL(toProps) : navOnPress;
		}
	}

	const internalOnPress = React.useCallback(() => {
		clearTimeout(showPopoverTimeout);
		popoverRef.current?.hide();
		if (
			requiredLevel &&
			((allowLegendaryShare && !HasLevelOrShare(currentLevel, requiredLevel)) ||
				(!allowLegendaryShare && currentLevel < requiredLevel))
		) {
			lockedModalRef.current && lockedModalRef.current.show();
			return;
		}	
		onPress && onPress();
	}, [onPress, requiredLevel, allowLegendaryShare, currentLevel]);

	const internalNavOnPress = React.useCallback(
		(e) => {
			clearTimeout(showPopoverTimeout);
			popoverRef.current?.hide();
			navOnPress && navOnPress(e);
		},
		[navOnPress, showPopoverTimeout]
	);

	if (hide) {
		return null;
	}

	const hasIcon = icon || iconComponent;
	const iconOnly = hasIcon && !title;
	const textAndIcon = hasIcon && title;

	// Style button
	const boxStyle = [styles.box];
	if (!list) {
		size === "small" && boxStyle.push(styles.small);
		size === "large" && boxStyle.push(styles.large);
		size === "average" && !textAndIcon && boxStyle.push(styles.average);
		size === "average" && textAndIcon && boxStyle.push(styles.averageWithIcon);
		size === "fullWidth" && boxStyle.push(styles.fullWidth);
		iconOnly && boxStyle.push(styles.iconOnly);
	}

	list && boxStyle.push(styles.list);
	!hasIcon && list && boxStyle.push(styles.listNoIcon);
	danger && !list && boxStyle.push(styles.boxDanger);
	(transparent || transparentHighlight) && boxStyle.push(styles.boxTransparent);
	transparentHighlight && boxStyle.push(styles.boxTransparentHighlight);
	floating && boxStyle.push(styles.floating);
	popupMode && boxStyle.push(styles.boxPopup);
	textAndIcon && boxStyle.push(styles.boxWithIcon);
	callToAction && boxStyle.push(styles.boxCallToAction);

	disabled && boxStyle.push(styles.disabled);

	footerForward && boxStyle.push(styles.footerForward);
	footerBack && boxStyle.push(styles.footerBack);
	isToggle && boxStyle.push(styles.toggleButton);
	toggled && boxStyle.push(styles.toggled);

	const boxHoverStyle = [styles.boxHover];
	transparent && boxHoverStyle.push(styles.boxTransparentHover);
	list && boxHoverStyle.push(styles.boxListHover);
	callToAction && boxHoverStyle.push(styles.boxCallToActionHover);
	boxHoverStyle.push(overrideHoverStyle);

	// Style text
	const textContainerStyle = [];
	list && textContainerStyle.push(styles.listTextContainer);
	textAndIcon && textContainerStyle.push(styles.textWithIconContainer);
	styleTextContainer && textContainerStyle.push(styleTextContainer);

	const textStyle = [styles.defaultText];
	list && textStyle.push(styles.listText);
	textAndIcon && textStyle.push(styles.textWithIcon);
	styleText && textStyle.push(styleText);

	// Style container
	const containerStyleArray = [];
	centerButton && containerStyleArray.push(containerStyles.centered);
	floating && containerStyleArray.push(styles.floatingContainer);

	if (!iconComponent && icon) {
		iconComponent = (
			<AppIcon
				color={
					danger ? (list ? global.colors.dangerLight : global.colors.textReversed) : global.colors.textDefault
				}
				size={24}
				{...icon}
			/>
		);
	}

	const subiconSize = subicon?.size || 16;
	let subiconComponent = subicon && <AppIcon size={subiconSize} color={global.colors.textDefault} {...subicon} />;

	// Style icon
	const iconStyle = [styles.iconContainer];
	iconOnly && iconStyle.push(styles.iconContainerIconOnly);
	iconContainerStyle && iconStyle.push(iconContainerStyle);

	let buttonComponent = (
		<Hoverable
			onHoverIn={() => (showPopoverTimeout = setTimeout(() => popoverRef.current?.show(), popoverDelay))}
			onHoverOut={() => {
				clearTimeout(showPopoverTimeout);
				popoverRef.current?.hide();
			}}
		>
			{(isHovered) => (
				<Container
					onPress={internalOnPress}
					onClick={internalNavOnPress}
					disabled={disabled}
					style={[boxStyle, style, isHovered && !disabled && [boxHoverStyle, hoverStyle]]}
					{...linkProps}
					target={useExternalURL ? "_blank" : null}
				>
					<CondView style={iconStyle} show={iconComponent}>
						{iconComponent}
						<CondView show={subiconComponent} style={[styles.subicon, { bottom: 0, right: 0 }]}>
							{subiconComponent}
						</CondView>
					</CondView>
					<View style={[textContainerStyle]}>
						<AppText
							style={textStyle}
							bold
							color={
								callToAction && isWeb()
									? "textDark"
									: transparent && isHovered
									? "hint"
									: danger && list
									? "dangerLight"
									: danger
									? "textLight"
									: "textDefault"
							}
						>
							{title}
						</AppText>
						<AppText hide={!info} style={textStyle} color="hintLight" {...infoTextProps}>
							{info}
						</AppText>
					</View>
					{requiredLevel && (
						<LockedFeatureModal
							ref={lockedModalRef}
							requiredLevel={requiredLevel}
							navigation={navigation}
						/>
					)}
					{!!badgeValue && <UnreadBadge value={badgeValue} important />}
				</Container>
			)}
		</Hoverable>
	);

	if (callToAction && isWeb()) {
		buttonComponent = <div className="call_to_action">{buttonComponent}</div>;
	}

	if (popoverProps) {
		buttonComponent = (
			<Popover ref={popoverRef} noClose localCoordinates {...popoverProps}>
				{buttonComponent}
			</Popover>
		);
	}

	if (!containerStyleArray.length || size === "fullWidth") {
		return buttonComponent;
	}

	return <View style={[containerStyleArray]}>{buttonComponent}</View>;
}

const mapStateToProps = (state, ownProps) => {
	let users = [];
	if (ownProps.allowLegendaryShare) {
		// refresh when users are updated
		users = state.users;
	}
	return {
		currentLevel: state.user?.profile.reward_level,
		users,
	};
};

export default connect(mapStateToProps)(AppButton);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		box: {
			backgroundColor: colors.buttonBackgroundColor,
			alignItems: "center",
			justifyContent: "center",
			borderColor: colors.borderDefault,
			borderRadius: 6,
			paddingVertical: 8,
			paddingHorizontal: 4,
			minHeight: 48,
			marginVertical: 8,
			flexDirection: "row",
		},

		boxHover: {
			backgroundColor: colors.buttonBackgroundColorLight,
		},

		boxWithIcon: {
			justifyContent: "center",
		},

		boxCallToAction: {
			backgroundColor: colors.primary,
		},

		boxCallToActionHover: {
			backgroundColor: colors.primaryLight,
		},

		boxDanger: {
			backgroundColor: colors.danger,
			borderColor: "transparent",
		},

		boxTransparent: {
			borderColor: "transparent",
			backgroundColor: "transparent",
		},

		boxTransparentHighlight: {
			borderColor: colors.lightBorder,
			borderWidth: 1,
		},

		boxTransparentHover: {
			backgroundColor: "transparent",
		},

		boxListHover: {
			backgroundColor: colors.hoverItemBackground,
		},

		boxPopup: {
			backgroundColor: colors.popupBackground,
		},

		iconOnly: {
			width: 48,
		},

		small: {
			width: 120,
		},
		average: {
			width: 144,
		},

		averageWithIcon: {
			width: 184,
		},

		large: {
			width: 360,
		},
		fullWidth: {
			flex: 1,
			borderWidth: 0,
			marginVertical: 0,
			borderRadius: 0,
		},
		list: {
			width: "100%",
			marginVertical: 0,
			borderWidth: 0,
			borderRadius: 0,
			justifyContent: "flex-start",
			marginHorizontal: 0,
			minWidth: 180,
			paddingRight: 8,
		},

		listNoIcon: {
			paddingLeft: 8,
		},

		textWithIconContainer: {
			flex: 1,
			marginHorizontal: 0,
		},

		listTextContainer: {
			marginHorizontal: 16,
			flex: 1,
		},

		disabled: {
			opacity: 0.4,
		},

		iconContainer: {
			marginRight: 8,
			alignItems: "center",
			justifyContent: "center",
			width: 32,
			height: 32,
		},

		iconContainerIconOnly: {
			marginLeft: 0,
		},

		toggleButton: {
			borderWidth: 1,
			borderRadius: 20,
			backgroundColor: "transparent",
			borderColor: colors.boldBorder,
		},

		defaultText: {
			textAlign: "center",
		},

		textWithIcon: {
			textAlign: "left",
		},

		listText: {
			textAlign: "left",
		},

		toggled: {
			backgroundColor: colors.primary,
			// borderColor: colors.attention,
			// borderWidth: 2,
		},

		footerBack: {
			backgroundColor: colors.cancel,
		},

		footerForward: {
			backgroundColor: colors.secondary,
		},

		floatingContainer: {
			position: "absolute",
			bottom: 8,
			right: 0,
			left: 0,
			paddingHorizontal: 8,
		},

		floating: {
			flex: 1,
			width: "100%",
		},
		subicon: {
			position: "absolute",
			borderRadius: 100,
			backgroundColor: colors.cardBackground,
			padding: 1,
		},
	})
);

const containerStyles = StyleSheet.create({
	centered: {
		alignItems: "center",
		justifyContent: "center",
	},
});
