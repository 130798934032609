import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { _ } from "../../i18n/i18n";
import { apiFetch } from "../../network/apiFetch";
import { socket } from "../../network/websocket";
import { fetchAvatars } from "../../store/slices/avatarsSlice";
import { fetchBookmarks } from "../../store/slices/bookmarksSlice";
import { fetchCharacters } from "../../store/slices/charactersSlice";
import { fetchCharacterStates } from "../../store/slices/characterStatesSlice";
import { fetchcharacterTags } from "../../store/slices/characterTagsSlice";
import { fetchDiceSets } from "../../store/slices/dicesetsSlice";
import { fetchExportGameRequests } from "../../store/slices/exportGameRequestsSlice";
import { fetchGame, removeGameFromStore } from "../../store/slices/gamesSlice";
import {
	setCurrentCharacterId,
	setFocusedType,
	setGameReady,
	setLinesDisplayed,
} from "../../store/slices/gamesUISlice";
import { fetchGameHelpers, setOpenedHelper } from "../../store/slices/interactiveHelpersSlice";
import { fetchLanguages } from "../../store/slices/languagesSlice";
import { fetchGameBoundaries, setBoundaries } from "../../store/slices/linesSlice";
import { fetchGameMacros } from "../../store/slices/macrosSlice";
import { refreshCurrentParty, setPovCharacterId } from "../../store/slices/partiesSlice";
import { fetchRollRequests } from "../../store/slices/rollRequestsSlice";
import { fetchGameSheets } from "../../store/slices/sheetsSlice";
import { fetchTokens } from "../../store/slices/tokensSlice";
import { fetchGameUsers } from "../../store/slices/usersSlice";
import { isAssistantGM, isGM, isInGame, isMainGM, isPlayer } from "../../tools/games";
import { isWeb } from "../../tools/generic";
import { pickDefaultPovCharacterId, pickPovForParty } from "../../tools/parties";
import { cancellablePromiseChain } from "../../tools/promises";
import { usePrevious } from "../../tools/react";
import AppScreenView from "../AppScreenView";
import { SELECT_CHARACTER_CHANGE_ACTIVE } from "../characters/FilteredCharacterList";
import ErrorLoading from "../errors/ErrorLoading";
import AppActivityIndicator from "../generic/AppActivityIndicator";
import AppText from "../generic/AppText";
import EmptyScreenView from "../generic/EmptyScreenView";
import BoxModal from "../generic/modal/BoxModal";
import OverflowMenu from "../generic/OverflowMenu";
import LinesList from "../lines/LinesList";
import CurrentPartyHeader from "../parties/CurrentPartyHeader";
import Stage from "../stage/Stage";
import EmptyGameScreen from "./EmptyGameScreen";
import ExportGameConsent from "./ExportGameConsent";
import GameMenu from "./GameMenu";

function GameScreen({
	route,
	navigation,
	dispatch,
	user,
	characters,
	avatarsLoaded,
	currentCharacterId,
	povCharacterId,
	openedParties,
	linesDisplayed,
	gameReady,
	game,
	boundaries,
	characterSelectedFromMenu,
	exportGameRequests,
	lines
}) {
	if (!game) return <ErrorLoading />;
	const { party: partyIdToOpen, characterSelectedCodeSent } = route.params;
	const localIsPlayer = isPlayer(game, user);
	const localIsInGame = isInGame(user, game);
	const confirmLeaveModal = React.useRef();


	const previousPartiesCount = usePrevious(game.parties?.length);
	const previousLocalInGame = usePrevious(localIsInGame);

	const [filteredLines, setFilteredLines] = React.useState(lines);
	const [searchIsActive, setActiveSearch] = React.useState(false);
	const [currentSearch, setCurrentSearch] = React.useState(null);

	const onSearchUpdateCallback = React.useCallback((search) => {
		setActiveSearch(!!search);
		if (!!search) {
			setCurrentSearch(search);
			apiFetch('search-rolegate/game-text', "GET", {search_param: search.toLowerCase(), game_id: game.id}).then((response) => {
				let filteredResults =
					// NOTE for some reason I cannot identify, the doing a search on the dev mobile app returns data that does not fit the search criteria.
					//      This filter is a bandaid that ensures the response is accurate for those cases.
					!isWeb() ? response.results.filter((line) => line.content.toLowerCase().includes(search.toLowerCase())) : response.results;
				setFilteredLines(filteredResults);
				setActiveSearch(false)
			})
		} else {
			setFilteredLines(lines);
			setCurrentSearch(null);
		}
	}, [lines])

	const onLeaveConfirmed = React.useCallback(() => {
		dispatch(removeGameFromStore(game));
		apiFetch(`users/${user.id}/games/${game.id}/leave`, "POST");
		if (isWeb()) {
			navigation.navigate("GameSettingScreen", { gameslug: game.slug, updating_game_data: true });
		} else {
			navigation.navigate("Home");
		}
	}, [game, user?.id]);

	React.useEffect(() => {
		if (game.id) {
			loadGameData();
		}
	}, [game.id]);

	const isEmpty = !boundaries?.firstLineId;

	const receiveLine = React.useCallback(
		(line) => {
			if (isEmpty) {
				dispatch(setBoundaries({ gameId: game.id, firstLineId: line.id, lastLineId: line.id }));
				dispatch(fetchGameBoundaries(game.id, openedParties?.map(p => p.id)));
			}
		},
		[game.id, isEmpty, openedParties]
	);

	React.useEffect(() => {
		if (isEmpty) socket.on("new_line", receiveLine);
		return () => {
			socket.off("new_line", receiveLine);
		};
	}, [receiveLine, isEmpty]);

	const gameUnreadLines = React.useMemo(
		() => user?.unread_lines.filter((l) => l.game === game.id) || [],
		[user?.unread_lines]
	);

	React.useEffect(() => {
		const gm = isGM();
		const inGame = isInGame();
		const options = [];

		const unreadPublic = gameUnreadLines.filter((l) => l.is_comment).length;

		options.push({
			title: _("Public chat", "screen title"),
			disabled: !game.allow_public_chat,
			onPress: () => navigation.navigate("PublicChatScreen"),
			icon: { type: MaterialIcons, name: "chat-bubble" },
			badgeValue: unreadPublic,
		});
		!isWeb() &&
			options.push({
				title: _("Index", "game index page"),
				onPress: () => navigation.navigate("IndexScreen"),
			});

		isMainGM() &&
			options.push({
				title: _("Edit template"),
				onPress: () =>
					navigation.navigate("CharacterSheetStackGame", {
						screen: "CharacterSheetTemplateScreen",
						params: {
							sheetId: game.sheet_template,
							isTemplate: true,
							gameId: game.id,
						},
					}),
			});
		gm &&
			options.push({
				title: _("Manage languages", "manage game fictional languages"),
				onPress: () => navigation.navigate("ManageLanguagesScreen", { game }),
			});

		inGame &&
			!isWeb() &&
			options.push({
				title: _("Interactive helpers", "screen title"),
				onPress: () => dispatch(setOpenedHelper(undefined)),
				icon: { type: MaterialCommunityIcons, name: "paperclip" },
			});

		inGame &&
			!global.appleStoreReview &&
			options.push({
				title: _("Macros"),
				onPress: () => navigation.navigate("ManageMacrosScreen", { gameId: game.id }),
			});

		linesDisplayed === "mixed" &&
			inGame &&
			options.push({
				title: _("Hide story messages"),
				onPress: () => {
					dispatch(setLinesDisplayed({ gameId: game.id, value: "chat" }));
					dispatch(setFocusedType({ gameId: game.id, value: "chat" }));
				},
			});
		linesDisplayed === "mixed" &&
			inGame &&
			options.push({
				title: _("Hide OOC messages"),
				onPress: () => {
					dispatch(setLinesDisplayed({ gameId: game.id, value: "story" }));
					dispatch(setFocusedType({ gameId: game.id, value: "story" }));
				},
			});
		linesDisplayed !== "mixed" &&
			inGame &&
			options.push({
				title: linesDisplayed === "chat" ? _("Show story messages") : _("Show OOC messages"),
				onPress: () => {
					dispatch(setLinesDisplayed({ gameId: game.id, value: "mixed" }));
					dispatch(setFocusedType({ gameId: game.id, value: linesDisplayed === "chat" ? "story" : "chat" }));
				},
			});
		options.push({
			title: _("Share", "share game option"),
			onPress: () => navigation.navigate("ShareGameModal", { gameslug: game.slug }),
		});

		(localIsPlayer || isAssistantGM()) &&
			options.push({
				title: _("Leave game"),
				danger: true,
				onPress: () => confirmLeaveModal.current?.show(),
			});

		navigation.setOptions({
			headerRight: () => <OverflowMenu options={options} badgeValue={unreadPublic} />,
		});
	}, [navigation, gameUnreadLines, game, linesDisplayed, localIsPlayer]);

	const loadGameData = React.useCallback(async () => {
		let isMounted = true;
		if (!avatarsLoaded) {
			await dispatch(fetchAvatars());
		}

		await cancellablePromiseChain(
			() => !isMounted,
			[
				() => dispatch(fetchGame(game.id)),
				() => dispatch(fetchCharacters(game.id)),
				() => dispatch(fetchCharacterStates(game.id)),
				() => dispatch(fetchDiceSets(game.id)),
			]
		);

		if (!localIsInGame) {
			await dispatch(setLinesDisplayed({ gameId: game.id, value: "story" }));
		} else if (user) {
			await dispatch(fetchBookmarks(user.id, [game.id]));
		}

		dispatch(setGameReady({ gameId: game.id, value: true }));

		await cancellablePromiseChain(
			() => !isMounted,
			[
				() => dispatch(fetchGameSheets(game.id)),
				() => dispatch(fetchGameUsers(game.id)),
				() => dispatch(fetchcharacterTags(game.id)),
			]
		);

		if (user) {
			await dispatch(fetchGameHelpers(user.id, game.id));
		}

		if (localIsInGame) {
			cancellablePromiseChain(
				() => !isMounted,
				[
					() => dispatch(fetchTokens(game.id)),
					() => dispatch(fetchRollRequests(game.id)),
					() => dispatch(fetchGameMacros(game)),
					() => dispatch(fetchLanguages(game.id, isGM() ? null : currentCharacterId)),
					() => dispatch(fetchExportGameRequests(game.id)),
				]
			);
		}

		return () => (isMounted = false);
	}, [game, user, avatarsLoaded, currentCharacterId, localIsInGame]);

	React.useEffect(() => {
		if (localIsInGame && !previousLocalInGame) {
			dispatch(setLinesDisplayed({ gameId: game.id, value: "mixed" }));
		}
	}, [localIsInGame, game.id]);

	// Set current character
	React.useEffect(() => {
		if (currentCharacterId) return () => null;
		const currentCharacter = user ? characters.find((c) => c.player?.id === user?.id) : null;
		const gm = isGM();
		if (!gm && localIsPlayer) {
			dispatch(setCurrentCharacterId({ gameId: game.id, value: currentCharacter?.id }));
			dispatch(setPovCharacterId({ gameId: game.id, characterId: currentCharacter?.id }));
		}
	}, [characters, game.id, localIsPlayer, currentCharacterId]);

	React.useEffect(() => {
		if (
			characterSelectedCodeSent === SELECT_CHARACTER_CHANGE_ACTIVE &&
			characterSelectedFromMenu?.id !== currentCharacterId
		) {
			navigation.setParams({ characterSelectedCodeSent: null });
			dispatch(setCurrentCharacterId({ gameId: game.id, value: characterSelectedFromMenu?.id }));
			if (localIsPlayer && characterSelectedFromMenu) {
				dispatch(setPovCharacterId({ gameId: game.id, characterId: characterSelectedFromMenu.id }));
			}
		}
	}, [characterSelectedCodeSent, currentCharacterId, game.id, localIsPlayer]);

	// Set the POV (and current party as a consequence)
	React.useEffect(() => {
		if (!povCharacterId && characters?.length && !localIsPlayer && game.parties && !partyIdToOpen) {
			const newPovCharacterId = pickDefaultPovCharacterId(game, characters);
			if (newPovCharacterId) dispatch(setPovCharacterId({ gameId: game.id, characterId: newPovCharacterId }));
		}
	}, [characters, game, povCharacterId, localIsPlayer, partyIdToOpen]);

	React.useEffect(() => {
		if (!gameReady) return () => null;
		// Cannot open a party that I not opened by default if I am a player. Only GM and readers can.
		if (localIsPlayer && partyIdToOpen) {
			if (game.parties && !openedParties?.some((p) => p.id === partyIdToOpen)) {
				// switch character if I have one in the party to open
				const partyToOpen = game.parties?.find((p) => p.id === partyIdToOpen);
				const newCharacter = characters.find(
					(c) => c.player?.id === user.id && partyToOpen?.characters.includes(c.id)
				);
				if (newCharacter) {
					dispatch(setCurrentCharacterId({ gameId: game.id, value: newCharacter.id }));
					dispatch(setPovCharacterId({ gameId: game.id, characterId: newCharacter.id }));
				}
			}
			navigation.setParams({ party: null });
			return () => null;
		}

		// If the party id to open is not opened
		if (partyIdToOpen && !openedParties?.some((p) => p.id === partyIdToOpen)) {
			const partyToOpen = game.parties?.find((p) => p.id === partyIdToOpen);
			const characterId = pickPovForParty(characters, partyToOpen);
			dispatch(setPovCharacterId({ gameId: game.id, characterId }));
		} else if (partyIdToOpen) {
			navigation.setParams({ party: null });
		}
	}, [partyIdToOpen, openedParties, game, localIsPlayer, characters, gameReady, user?.id]);

	// Fetch game boundaries. Two cases: player only sees their own parties, or user sees everything
	React.useEffect(() => {
		dispatch(fetchGameBoundaries(game.id, !localIsInGame, openedParties?.map(p => p.id)));
	}, [localIsInGame, game.id, openedParties]);

	const previousGameParties = usePrevious(game.parties);

	React.useEffect(() => {
		if (
			previousGameParties &&
			game.parties &&
			(game.parties.length != previousGameParties.length ||
				game.parties.filter((p) => p.on_going).length != previousGameParties.filter((p) => p.on_going).length)
		) {
			dispatch(setPovCharacterId({ gameId: game.id, characterId: povCharacterId }));
		} else {
			// this makes sure the current party is still on_going
			dispatch(refreshCurrentParty(game.id))
		}
	}, [game.id, game.parties, povCharacterId]);

	if (partyIdToOpen && !openedParties?.some((p) => p.id === partyIdToOpen)) {
		return null;
	}

	// Not the fully loaded game
	if (!gameReady) {
		return (
			<AppScreenView style={{ flex: 1, justifyContent: "center" }}>
				<AppActivityIndicator />
			</AppScreenView>
		);
	}

	if (!localIsInGame && !game.allow_spectators) {
		return (
			<EmptyScreenView
				messageTop={_("This game does not allow readers.")}
				icon={{ type: MaterialCommunityIcons, name: "eye-off" }}
			/>
		);
	}
	let failedSearch = !!currentSearch ? (searchIsActive ? false : filteredLines.length === 0) : false;

	let gameComponent = (
		<>
			<CurrentPartyHeader gameId={game.id} />
			{(isEmpty && 
				<EmptyGameScreen game={game} />) || 
				<LinesList game={game} localIsInGame={localIsInGame} failedSearch={failedSearch} disableLoading={searchIsActive} activeSearch={searchIsActive} searchedLines={filteredLines}
			/>}
			<Stage onSearchUpdatedCallback={onSearchUpdateCallback} failedSearch={failedSearch} />

			{exportGameRequests?.length > 0 && <ExportGameConsent exportRequest={exportGameRequests[0]} />}

			<BoxModal
				ref={confirmLeaveModal}
				title={_("Leave game")}
				message={
					<>
						<AppText>{_("Do you really want to leave this game?")}</AppText>
						<AppText color="hint" style={{ marginTop: 8 }}>
							{_("Your character will remain in the game as an NPC.")}
						</AppText>
					</>
				}
				addCancel
				options={[{ title: _("Leave", "leave game button"), onPress: onLeaveConfirmed, danger: true }]}
			/>
		</>
	);

	if (isWeb()) {
		return (
			<AppScreenView borderless style={{ paddingTop: 0, flexDirection: "row" }}>
				<View style={{ justifyContent: "flex-end", flex: 1 }}>{gameComponent}</View>
				<GameMenu />
			</AppScreenView>
		);
	}

	return (
		<AppScreenView borderless style={{ paddingTop: 0, justifyContent: "flex-end", flex: 1 }}>
			{gameComponent}
		</AppScreenView>
	);
}

const mapStateToProps = (state, ownProps) => {
	const gameId = ownProps.gameId;
	const game = state.games[gameId] || state.games[ownProps.route.params?.gameslug];
	if (!game) return {};
	const user = state.user;
	const gameUI = state.gamesUI[game.id];
	const boundaries = state.gameLinesBoundaries[game.id];

	return {
		user,
		game,
		avatarsLoaded: !state.avatars.unloaded,
		characters: state.charactersByGame[game.id] || Array.rg_empty,
		currentCharacterId: gameUI?.currentCharacterId,
		povCharacterId: state.parties[game.id]?.povCharacterId,
		openedParties: state.parties[game.id]?.openedParties,
		linesDisplayed: gameUI?.linesDisplayed || "mixed",
		gameReady: gameUI?.gameReady,
		boundaries,
		characterSelectedFromMenu: state.characters[ownProps.route.params?.characterSelectedId],
		exportGameRequests: state.exportGameRequests[game.id],
		lines: state.lines[game.id] || Array.rg_empty,
	};
};

export default connect(mapStateToProps)(GameScreen);
