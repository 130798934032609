import { useNavigation } from "@react-navigation/core";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Animated from "react-native-reanimated";
import { Hoverable, useHover } from "react-native-web-hooks";
import { connect } from "react-redux";
import { BuildStyleMethod } from "../../../styles/theming";
import { HasLevelOrShare } from "../../../tools/account";
import { ws } from "../../../tools/generic";
import CondView from "../../meta/CondView";
import LockedFeatureModal from "../../profile/LockedFeatureModal";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import Popover from "../popover/Popover";
import UnreadBadge from "../UnreadBadge";

let showPopoverTimeout = null;

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

function IconButton({
	transparent,
	onPress,
	onLongPress,
	disabled,
	icon,
	subicon,
	style,
	hide,
	title,
	titleColorName,
	square,
	toggled,
	iconContainerStyle,
	useAnimated,
	badgeValue,
	iconComponent,
	popoverProps,
	popoverDelay = 500,
	navigation,
	requiredLevel,
	allowLegendaryShare,
	stopPropagation,
	dispatch,
	currentLevel,
	users,
}) {
	const styles = stylesMethod(global.theme);
	if (hide) return null;

	if (global.appleStoreReview && requiredLevel) return null;

	const lockedModalRef = React.useRef();

	const iconColor =
		icon?.color || (toggled === false || transparent ? global.colors.textDefault : global.colors.textLight);
	const iconSize = icon?.size || 24;

	iconComponent = iconComponent || (icon && <AppIcon size={iconSize} {...icon} color={iconColor} />);

	const subiconSize = subicon?.size || 16;
	let subiconComponent = subicon && <AppIcon size={subiconSize} color={global.colors.textDefault} {...subicon} />;

	const buttonStyle = [styles.container];
	transparent && buttonStyle.push(styles.containerTransparent);
	disabled && buttonStyle.push(styles.disabled);
	square && buttonStyle.push(styles.containerSquare);
	toggled !== undefined && buttonStyle.push(styles.containerTogglable);
	toggled === true && buttonStyle.push(styles.containerToggled);
	toggled === false && buttonStyle.push(styles.containerUntoggled);

	const ComponentTag = useAnimated ? AnimatedTouchable : TouchableOpacity;

	const popoverRef = React.useRef();

	const ref = React.useRef(null);
	const isHovered = useHover(ref);

	if (!navigation && requiredLevel) navigation = useNavigation();	

	const internalOnPress = React.useCallback((e) => {		
		if(stopPropagation){
			e.stopPropagation();
			e.preventDefault();
		}
		clearTimeout(showPopoverTimeout);
		popoverRef.current?.hide();

		if (
			requiredLevel &&
			((allowLegendaryShare && !HasLevelOrShare(currentLevel, requiredLevel)) ||
				(!allowLegendaryShare && currentLevel < requiredLevel))
		) {
			lockedModalRef.current && lockedModalRef.current.show();
			return;
		}

		onPress && onPress();
	}, [onPress, requiredLevel, allowLegendaryShare, currentLevel, stopPropagation]);

	let buttonComponent = (
		<Hoverable
			onHoverIn={() => (showPopoverTimeout = setTimeout(() => popoverRef.current?.show(), popoverDelay))}
			onHoverOut={() => {
				clearTimeout(showPopoverTimeout);
				popoverRef.current?.hide();
			}}
		>
			<ComponentTag
				ref={ref}
				onPress={internalOnPress}
				onLongPress={onLongPress}
				delayLongPress={global.delayLongPress}
				style={[buttonStyle, isHovered && !disabled && styles.containerHovered, style]}
				disabled={disabled}
			>
				<View style={iconContainerStyle}>
					{iconComponent}
					<CondView
						show={subiconComponent}
						style={[styles.subicon, { bottom: subiconSize * -0.5, right: subiconSize * -0.5 }]}
					>
						{subiconComponent}
					</CondView>
				</View>
				{!!title && (
					<AppText size="small" centered color={titleColorName}>
						{title}
					</AppText>
				)}
				{!!badgeValue && <UnreadBadge value={badgeValue} important style={styles.badge} />}
				{requiredLevel && (
					<LockedFeatureModal ref={lockedModalRef} requiredLevel={requiredLevel} navigation={navigation} />
				)}
			</ComponentTag>
		</Hoverable>
	);

	if (popoverProps) {
		buttonComponent = (
			<Popover ref={popoverRef} noClose localCoordinates {...popoverProps}>
				{buttonComponent}
			</Popover>
		);
	}

	return buttonComponent;
}

const mapStateToProps = (state, ownProps) => {
	let users = [];
	if (ownProps.allowLegendaryShare) {
		// refresh when users are updated
		users = state.users;
	}
	return {
		currentLevel: state.user?.profile.reward_level,
		users,
	};
};

export default connect(mapStateToProps)(IconButton);

const stylesMethod = BuildStyleMethod((colors) =>
	StyleSheet.create({
		container: {
			backgroundColor: colors.primary,
			borderRadius: 100,
			height: ws(32, 48),
			width: ws(32, 48),
			alignItems: "center",
			justifyContent: "center",
			borderWidth: 1,
			borderColor: "transparent",
		},
		containerHovered: {
			borderColor: colors.lightBorder,
			backgroundColor: colors.hoverItemLightBackground,
		},
		containerTransparent: {
			backgroundColor: "transparent",
		},
		containerSquare: {
			borderRadius: 0,
		},
		containerTogglable: {
			borderWidth: 1,
			backgroundColor: colors.secondary,
		},
		containerToggled: {
			backgroundColor: colors.attention,
		},
		containerUntoggled: {
			backgroundColor: colors.secondary,
		},
		disabled: {
			opacity: 0.5,
		},
		subicon: {
			position: "absolute",
			bottom: -6,
			right: -6,
			borderRadius: 100,
			backgroundColor: colors.cardBackground,
			padding: 1,
		},
		badge: {
			position: "absolute",
			right: 0,
			top: 0,
			width: 24,
		},
	})
);
